import CampaignList from '../../../components/campaign/list/campaignList';

export default {
  name: 'InAppCampaignListComponent',
  data() {
    return {
      campaignChannelType: 'inApp',
      campaignChannel: 'InApp'
    };
  },
  components: {
    CampaignList
  },
  methods: {},
  mounted() {}
};
